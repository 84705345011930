import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-under-construction',
  templateUrl: './module-under-construction.component.html',
  styleUrls: ['./module-under-construction.component.scss']
})
export class ModuleUnderConstructionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
