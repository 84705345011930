<div class="container">
  <div
    class="
      layout-container
      d-flex
      flex-column flex-lg-row
      justify-content-center
      align-items-center
    "
  >
    <div>
      <h2>Módulo en construcción</h2>
      <p>
        Estamos trabajando para que puedas hacer uso de las funcionalidades de
        este módulo próximamente :)
      </p>
      <button
        class="mt-2"
        mat-flat-button
        color="primary"
        routerLink="/dashboard"
        class="my-4"
      >
        <i class="fas fa-home mr-2"></i>
        Regresar
      </button>
    </div>

    <img src="../../../assets/img/under-construction.png" />
  </div>
</div>
