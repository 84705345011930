<nav class="navbar navbar-expand-md">
  <!-- brand -->
  <div class="title-container">
    <h2>{{ module }}</h2>
  </div>

  <!-- User -->
  <ul class="options-container d-flex align-items-center">
    <li class="nav-item d-none d-lg-block">
      <a>
        <i class="fas fa-user"></i>

        <div class="ml-2">
          <span class="text-sm">
            {{ !!user?.name ? user?.name + " " + user?.lastname : user?.email }}
          </span>
        </div>
      </a>
    </li>

    <li class="nav-item">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon><i class="fas fa-ellipsis-v"></i></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon><i class="fas fa-user mt"></i></mat-icon>
          <span>Mi perfil</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon><i class="fas fa-sign-out-alt"></i></mat-icon>
          <span>Salir</span>
        </button>
      </mat-menu>
    </li>
  </ul>
</nav>
