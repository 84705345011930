<div class="gradient-layout">
  <div class="container blurred-container">
    <div class="card">
      <div class="card-body">
        <div class="header">
          <div class="logo-container">
            <picture>
              <source srcset="../../../assets/img/new-epa-logo.webp" />
              <img
                src="../../../assets/img/new-epa-logo.png"
                alt="epa Digital"
              />
            </picture>
          </div>
          <h3 class="mt-2">
            EPA Digital <small class="text-muted">Newton</small>
          </h3>
        </div>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
