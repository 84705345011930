export const MODULES = {
  clientManagement: {
    id: 'clientManagement',
    name: 'Lorentz (Clientes)',
    type: 'module',
    icon: 'fas fa-user-tie',
    redirectPath: '/dashboard/customers',
  },
  userManagement: {
    id: 'userManagement',
    name: 'Euler (Usuarios)',
    type: 'module',
    icon: 'fas fa-users',
    redirectPath: '/dashboard/users',
  },
  budgetAudit: {
    id: 'budgetAudit',
    name: 'Deming (Presupuestos)',
    type: 'module',
    icon: 'fas fa-search-dollar',
    redirectPath: '/dashboard/budget-audit',
  },
  audit: {
    id: 'audit',
    name: 'Maxwell (Auditoría)',
    type: 'module',
    icon: 'fas fa-star',
    redirectPath: '/dashboard/audit',
  },
  pitagoras: {
    id: 'pitagoras',
    name: 'Pitágoras',
    type: 'tool',
    icon: 'fas fa-cloud-download-alt',
    redirectPath: '/dashboard/home',
  },
};

export const ROLES = {
  admin: {
    id: 'admin',
    name: 'Admin',
  },
  viewer: {
    id: 'viewer',
    name: 'Viewer',
  },
};

export const PAGES = {
  audit: {
    base: '/',
    clients: '/audit',
    accounts: '/audit/accounts',
    history: '/audit/history',
    optiscore: '/audit/optiscore',
  },
};

export const MODULES_TYPES = {
  Module: 'module',
  Tool: 'tool',
  Customer: 'customer',
};
