<div class="container">
  <div
    class="layout-container d-flex flex-column flex-lg-row justify-content-center align-items-center"
  >
    <div>
      <h2>Lo sentimos, la página no fue encontrada</h2>
      <p>No encontramos ninguna página asociada con esta URL</p>
      <button class="mt-2" mat-flat-button class="my-4" (click)="goBack()">
        <i class="fas fa-undo mr-2"></i>
        Regresar
      </button>
    </div>

    <img src="../../../assets/img/page-not-found.png" />
  </div>
</div>
ß
